/* eslint no-underscore-dangle: 0 */
import { action, computed, observable, runInAction, makeObservable, when } from 'mobx'
import viewStore from 'stores/viewStore'
import profileStore from 'stores/profileStore'
import * as API from 'stores/api'
import { formatPhone } from 'helpers/phoneFunctions'

class StaffStore {
  admin = {}

  staff = []

  staffList = []

  error = false

  loadingStaff = false

  staffMemberId = null

  staffMember = {}

  loadingStaffMember = false

  updatingStaffMember = false

  loadingWalks = false

  uploadingPhoto = false

  constructor() {
    makeObservable(this, {
      admin: observable,
      staff: observable,
      staffList: observable,
      error: observable,
      loadingStaff: observable,
      staffMemberId: observable,
      staffMember: observable,
      loadingStaffMember: observable,
      updatingStaffMember: observable,
      loadingWalks: observable,
      uploadingPhoto: observable,
      userPhoto: computed,
      hasSelectedStaffMember: computed,
      staffAndAdmin: computed,
      getStaff: action,
      getStaffToAssign: action,
      setCurrentStaffMember: action,
      getStaffMember: action,
      createStaffMember: action,
      updateStaffMember: action,
      canStaffBeDeleted: action,
      deleteStaffMember: action,
      uploadStaffPhoto: action,
    })

    when(
      () => profileStore.token,
      () => this.getStaff(),
    )
  }

  get userPhoto() {
    return this.staffMember && this.staffMember.photo
  }

  get hasSelectedStaffMember() {
    return this.staffMemberId
  }

  get staffAndAdmin() {
    return [this.admin, ...this.staff]
  }

  getStaff = async () => {
    this.error = null
    try {
      this.loadingStaff = true
      const { users } = await API.getStaffList()
      runInAction(() => {
        if (users) {
          this.admin = users.find((user) => user.roles.admin)
          this.staff = users.filter((user) => user.roles.staff && !user.roles.admin)
        }
        this.loadingStaff = false
      })
    } catch (e) {
      console.log('getStaff error', e)
      runInAction(() => {
        this.error = true
        this.loadingStaff = false
      })
    }
  }

  getStaffToAssign = async () => {
    this.error = null
    try {
      this.loadingStaff = true
      const { users } = await API.getStaffListToAssign()
      runInAction(() => {
        if (users) {
          this.staffList = users
        }
        this.loadingStaff = false
      })
      return users
    } catch (e) {
      console.log('getStaffToAssign error', e)
      runInAction(() => {
        this.error = true
        this.loadingStaff = false
      })
      return []
    }
  }

  setCurrentStaffMember(id) {
    this.staffMemberId = id
  }

  getStaffMember = async (staffId) => {
    const { staffMemberId } = this
    if (!staffId && !staffMemberId) {
      return {}
    }

    this.error = false
    this.loadingStaffMember = true
    try {
      const data = await API.getStaffMember(staffId || staffMemberId)
      if (data.error) {
        runInAction(() => {
          this.loadingStaffMember = false
          this.staffMember = {}
          this.error = 'USER_NOT_FOUND'
        })
        viewStore.notify('danger', 'StaffMember not found')
        return {}
      }
      runInAction(() => {
        this.staffMember = data.user
        this.loadingStaffMember = false
      })
      return data.user
    } catch (e) {
      console.log('getStaffMember error', e)
      // viewStore.notify('warning', 'Loading team member failed')
      runInAction(() => {
        this.loadingStaffMember = false
        this.error = true
        this.staffMember = {}
      })
      return {}
    }
  }

  async createStaffMember(data) {
    this.updatingStaffMember = true
    const userData = data
    userData.phone = formatPhone(userData.phone)

    try {
      const newStaffMember = await API.createStaffMember(userData)
      runInAction(() => {
        this.updatingStaffMember = false
        this.staffMemberId = newStaffMember._id
        this.staffMember = newStaffMember.user
      })
      return newStaffMember
    } catch (e) {
      console.log('createStaffMember failed', e)
      runInAction(() => {
        this.updatingStaffMember = false
        this.staffMember = {}
      })
      return {
        error: e,
        message: e.message || 'Creating staff failed',
      }
    }
  }

  async updateStaffMember(data, id) {
    const staffMemberId = id ?? this.staffMemberId
    const userData = data
    this.updatingStaffMember = true
    if (userData.phone) {
      userData.phone = formatPhone(userData.phone)
    }

    try {
      const response = await API.updateStaffMember(staffMemberId, userData)
      runInAction(() => {
        this.updatingStaffMember = false
        if (staffMemberId === this.staffMemberId) {
          this.staffMember = response.user
        }
        const index = this.staff.findIndex((staff) => staff._id === staffMemberId)
        this.staff[index] = response.user
      })
      return response
    } catch (e) {
      console.log('updateStaffMember failed', e)
      runInAction(() => {
        this.updatingStaffMember = false
      })
      return {
        error: e,
        message: e.message || 'Update failed',
      }
    }
  }

  canStaffBeDeleted = async (id) => {
    try {
      const result = await API.canStaffBeDeleted(id)
      console.log('canStaffBeDeleted', result)
      return result
    } catch (e) {
      console.log('canStaffBeDeleted error', e)
      // viewStore.notify('warning', e.message || `Team member not removed.`)
      return { success: false }
    }
  }

  deleteStaffMember = async (id) => {
    try {
      const result = await API.deleteStaffMember(id)
      console.log('deleteStaffMember', result)
      if (result.success) {
        viewStore.notify('success', result.message || 'Team member removed.')
      } else {
        viewStore.notify('warning', result.message || 'Team member not removed.')
      }
      return result
    } catch (e) {
      console.log('deleteStaffMember error', e)
      // viewStore.notify('warning', e.message || `Team member not removed.`)
      return { success: false }
    }
  }

  uploadStaffPhoto = async (photo) => {
    const { staffMemberId } = this
    this.uploadingPhoto = true

    try {
      const data = await API.uploadUserPhoto(staffMemberId, photo)
      runInAction(() => {
        this.uploadingPhoto = false
        console.log('UPLOADED PHOTO', data)
        if (data.user) {
          this.staffMember = data.user
        }
      })

      viewStore.notify('success', 'Photo uploaded.')

      return data
    } catch (e) {
      console.log('uploadStaffPhoto failed', e)
      runInAction(() => {
        this.uploadingPhoto = false
      })
      return {
        error: e,
        message: e.message || 'Update failed',
      }
    }
  }
}

export default new StaffStore()
