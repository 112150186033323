import { permissions } from 'helpers/permissions'
import { makeObservable, observable, action, reaction, computed } from 'mobx'

import companyStore from 'stores/companyStore'
import profileStore from 'stores/profileStore'
import staffStore from 'stores/staffStore'
import userStore from 'stores/userStore'

class ChatStore {
  contacts = {}
  minimised = true
  panel = 'contacts'
  to = {}

  constructor() {
    makeObservable(this, {
      contacts: observable,
      minimised: observable,
      panel: observable,
      to: observable,

      showChat: computed,
      chatEnabled: computed,

      open: action,
      close: action,
      toggle: action,
      selectContact: action,
      back: action,
      setUnread: action,
    })

    reaction(
      () => [staffStore.staffAndAdmin, profileStore.isClient, companyStore.company],
      ([staffs, isClient, company]) => {
        if (isClient) {
          this._updateStaffForClient(staffs, company)
        }
      },
    )
    reaction(
      () => [staffStore.staffAndAdmin, profileStore.isClient],
      ([staffs, isClient]) => {
        if (!isClient) {
          this._updateStaff(staffs)
        }
      },
    )
    reaction(() => userStore.users, this._updateClients)
  }

  // COMPUTEDS

  get showChat() {
    return companyStore.chatEnabled || profileStore.isAdmin
  }

  get chatEnabled() {
    return companyStore.chatEnabled
  }

  // ACTIONS

  open({ id, name } = {}) {
    companyStore.getMyCompany()
    this.minimised = false
    if (id) {
      name = name ?? chatStore.contacts[id]?.name ?? ''
      this.selectContact({ id, name })
    }
  }

  close() {
    this.minimised = true
  }

  toggle() {
    companyStore.getMyCompany()
    this.minimised = !this.minimised
  }

  selectContact(contact) {
    this.to = contact
    this.panel = 'chat'
  }

  back() {
    this.panel = 'contacts'
    this.to = {}
  }

  setUnread(id, count) {
    const contact = this.contacts[id]
    contact.unread.count = count
    if (count > 0) {
      contact.unread.at = Date.now()
    }
  }

  // REACTIONS

  _updateStaffForClient = (staffs, company) => {
    const newContacts = {}
    for (const staff of staffs) {
      if (staff.roles?.admin || staff.permissions.includes(permissions.CHAT_CLIENTS)) {
        const { _id: id, name, photo } = staff
        const displayPhoto = photo ?? company.logo
        newContacts[id] = {
          id,
          name,
          photo: displayPhoto,
          subtitle: company.name,
          tab: 'team',
          unread: { at: 0, count: 0 },
        }
      }
    }
    for (const contact of Object.values(this.contacts)) {
      // Keep other contacts
      if (contact.tab !== 'team' && !newContacts[contact.id]) {
        newContacts[contact.id] = contact
      }
    }
    this.contacts = newContacts
  }

  _updateStaff = (staffs) => {
    const newContacts = {}
    for (const staff of staffs) {
      const { _id: id, name, photo } = staff
      newContacts[id] = { id, name, photo, subtitle: '', tab: 'team', unread: { at: 0, count: 0 } }
    }
    for (const contact of Object.values(this.contacts)) {
      // Keep other contacts
      if (contact.tab !== 'team' && !newContacts[contact.id]) {
        newContacts[contact.id] = contact
      }
    }
    this.contacts = newContacts
  }

  _updateClients = (clients) => {
    const newContacts = {}
    for (const client of clients) {
      const { _id: id, name, pets } = client
      const subtitle = pets ? pets.map((p) => p.name).join(', ') : 'No Pets'
      let { photo } = client
      if (!photo && pets.length) {
        photo = pets[0].photo
      }
      newContacts[id] = { id, name, subtitle, photo, tab: 'clients', unread: { at: 0, count: 0 } }
    }
    for (const contact of Object.values(this.contacts)) {
      // Keep other contacts
      if (contact.tab !== 'clients') {
        newContacts[contact.id] = contact
      }
    }
    this.contacts = newContacts
  }

  // MISC

  getContacts(tab) {
    const contacts = Object.values(this.contacts).filter((contact) => !tab || contact.tab === tab)
    // Latest unread first
    const sorted = contacts.sort((a, b) => b.unread.at - a.unread.at)
    return sorted
  }
}

const chatStore = new ChatStore()

export default chatStore
